import React, { useCallback, useEffect, useState } from "react";
import { Router, Switch, Route, Link, Redirect } from "react-router-dom";

import "./App.css";

import Login from "./containers/Auth/Login";
import Profile from "./containers/Profile/Profile";

import { history } from "./helpers/history";
import { Menu } from "./containers/Menu";
import styled from "styled-components";
import Templates from "./containers/Templates/Templates";
import UpdateTemplate from "./containers/Templates/UpdateTemplate";
import { getCurrentUser, updateUser } from "./redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { addTemplateNotification, setIsOverlay } from "./redux/slices/templateSlice";
import { UserInfo } from "./containers/Profile/UserInfo";
import AuthLayout from "./layouts/AuthLayout";
import Register from "./containers/Auth/Register";
import ForgotPassword from "./containers/Auth/ForgotPassword";
import userService from "./services/user.service";
import PersonalInfoModal from "./containers/PersonalInfoModal";
import Notifications from "./containers/Notifications";
import NewPassword from "./containers/Auth/NewPassword";
import siteConfig from "./config";
import { Observable } from "rxjs";
import { notification } from "antd";
import OfferNotification from "./containers/OfferNotification";
import Notification from "./containers/Notification";
import { answerToInvitation, getGroups, getReceivedInvitations } from "./redux/slices/groupSlice";
import OnboardingModal from "./containers/OnboardingModal";

function fromEventSource(url) {
    return new Observable(subscriber => {
        const sse = new EventSource(url);

        sse.onmessage = e => subscriber.next(e);
        sse.onerror = e => subscriber.error(e);

        return () => {
            if (sse.readyState === 1) {
                sse.close();
            }
        };
    });
}

const App = () => {
    const token = localStorage.getItem('id_token');
    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const { isOverlay } = useSelector((state) => state.template);

    const { user } = useSelector((state) => state.user);

    const [isPersonalInfoModalOpen, setIsPersonalInfoModalOpen] = useState(false);
    const [isOnboardingInitialModalOpen, setIsOnboardingInitialModalOpen] = useState(false);

    let redirectUrl = token ? '/' : '/auth/login';

    const dispatch = useDispatch();


    const handleGroupAnswer = async (id, answer) => {

        await dispatch(answerToInvitation({
            groupInvitationId: id,
            status: answer
        }));

        notification.close(id);

        await dispatch(getReceivedInvitations());
        await dispatch(getGroups());
    };

    useEffect(() => {
        if (token) {
            dispatch(getCurrentUser());
        }
    }, [dispatch, token]);

    const handleClosePersonalInfoModal = () => {
        setIsPersonalInfoModalOpen(false);
        dispatch(getCurrentUser());
    };


    useEffect(() => {
        if (token) {
            if (user) {
                const timezoneOffset = -new Date().getTimezoneOffset();
                if (!user.timezoneOffset || timezoneOffset !== user.timezoneOffset) {
                    userService.updateUser({
                        timezoneOffset
                    });
                }

                // OnboardingSteps
                console.log('user.onboarding', user.onboardingStep)

                if (!user.isOnboardingFinished && user.onboardingStep) {

                    if (user.onboardingStep === 'SET_NAME') {
                        setIsPersonalInfoModalOpen(true);
                    } else if (['INITIAL', 'TELEGRAM'].includes(user.onboardingStep)) {
                        setIsOnboardingInitialModalOpen(true)
                    }
                }
            }
        }
    }, [user, dispatch, token]);

    useEffect(() => {
        if (isNotificationsOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isNotificationsOpen]);


    useEffect(() => {
        const userJson = localStorage.getItem('user');

        if (userJson) {
            const userId = JSON.parse(userJson).userId;
            fromEventSource(`${siteConfig.backendUrl}/notifications/${userId}/sse`)
                .subscribe(data => {
                    const push = JSON.parse(data.data);

                    onUpdateUser({
                        haveNewNotifications: true
                    });

                    if (push.type === 'GROUP_INVITATION') {
                        notification.open({
                            message: `Уведомление`,
                            description: <Notification notification={push.invitation} handleAnswer={handleGroupAnswer}/>,
                            placement: 'bottomLeft',
                            duration: 5,
                            key: push.invitation.id
                        });
                    } else {
                        dispatch(addTemplateNotification(push));
                        notification.open({
                            message: `Уведомление`,
                            description: <OfferNotification notification={push}/>,
                            placement: 'bottomLeft',
                            duration: 5,
                        });
                    }
                });
        }

        // eslint-disable-next-line
    }, []);

    const onOverlayClick = () => {
        dispatch(setIsOverlay(false));
        setIsHovered(false);
    };

    const onUpdateUser = useCallback((data) => {
        dispatch(updateUser(data));
    }, [dispatch]);

    const handleNotifications = useCallback(async () => {
        if (!isNotificationsOpen) {
            onUpdateUser({
                haveNewNotifications: false
            });
        }
        setIsNotificationsOpen((prevValue) => !prevValue);
    }, [isNotificationsOpen, onUpdateUser]);


    return (
        <Router history={history}>
            <Switch>
                <Route path="/auth/:path?">
                    <AuthLayout>
                        <Switch>
                            <Route path="/auth/new-password" component={NewPassword} />
                            <Route path="/auth/login" component={Login} />
                            <Route path="/auth/register" component={Register} />
                            <Route path="/auth/forgot-password" component={ForgotPassword} />
                        </Switch>
                    </AuthLayout>
                </Route>
                <Route path="/:path?">
                    <>
                        <MainLayout>
                            {isOverlay &&
                                <>
                                    <Overlay onClick={onOverlayClick}/> }
                                    <OverlayTooltip>
                                        <div> В настройках можно отредактировать шаблоны писем,<br/>чтобы не писать их вручную каждый раз. <Link
                                            onClick={() => dispatch(setIsOverlay(false))}
                                            to="/profile?isOpenParam=true">Попробовать</Link></div>
                                    </OverlayTooltip>
                                </>
                            }
                            {
                                token && user &&
                                <LeftBlock
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => { setIsHovered(false); setIsNotificationsOpen(false);}}
                                    style={ isOverlay ? { "width" :'200px', "z-index": '999999999' } : {} }
                                    isNotificationsOpen={isNotificationsOpen}
                                >
                                    <>
                                        <UserInfoContainer isOverlayOrIsHovered={isHovered || isOverlay}>
                                            <UserInfo openAccountInfo={() => setIsPersonalInfoModalOpen(true)} isOverlayOrIsHovered={isHovered || isOverlay}  />
                                        </UserInfoContainer>
                                        <Menu user={user} handleNotifications={handleNotifications} isHovered={isHovered} isOverlay={isOverlay} />
                                    </>
                                    <NotificationsContainer isNotificationsOpen={isNotificationsOpen}>
                                        <Notifications onClose={handleNotifications} />
                                    </NotificationsContainer>
                                </LeftBlock>

                            }
                            <RightBlock isOverlayOrIsHovered={isHovered || isOverlay}>
                                <Switch>
                                    <Route exact  path="/" component={Templates} />
                                    <Route exact  path="/profile" component={Profile} />
                                    <Route path="/update-template/:templateId" component={UpdateTemplate} />
                                    <Route  path="/templates" component={Templates} />
                                </Switch>
                                {isNotificationsOpen && <OverlayContainer /> }
                            </RightBlock>
                        </MainLayout>
                        {user && <PersonalInfoModal
                            visible={isPersonalInfoModalOpen}
                            onClose={handleClosePersonalInfoModal}
                            isOnRegistration={!user?.isOnboardingFinished}
                            openNextOnboardingModal={() => setIsOnboardingInitialModalOpen(true)}
                        />}
                        {user && ['INITIAL', 'TELEGRAM'].includes(user?.onboardingStep) &&
                            <OnboardingModal
                                visible={isOnboardingInitialModalOpen}
                                onboardingType={user?.onboardingStep}
                                user={user}
                                onClose={() => setIsOnboardingInitialModalOpen(false)}
                            />}
                    </>
                </Route>
                <Redirect to={redirectUrl} push={true} />
            </Switch>
        </Router>
    );
};

export default App;

const UserInfoContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 18px;
  pointer-events: ${props => props.isOverlayOrIsHovered ? 'auto' : 'none'};
`;

const OverlayTooltip = styled.div`
    position: absolute;
    top: 260px;
    z-index: 999;
    left: 220px;
    color: white;
    font-size: 18px;
    width: 600px;
`;

const Overlay = styled.div`
  z-index: 999;
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  background: black;
  opacity: .7;
`;

const OverlayContainer = styled.div`
  z-index: 9;
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  background: black;
  opacity: .7;
`;

const MainLayout = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;



const LeftBlock = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 70px;
    height: 100%;
    min-height: 100%;
    display: flex;
    align-items: start;
    background: #FFFFFF;
    
    z-index: 999;
    
    &:hover {
      width: ${(props) => props.isNotificationsOpen ? '535px' : '200px'};
    }
    
    .logo {
      position: absolute;
      bottom: 50px;
      left: 15px;
    }
`;


const NotificationsContainer = styled.div`
    width: 375px;
    height: 100%;
    margin-left: 40px;
    background: #efefef;
    display: ${(props) => props.isNotificationsOpen ? 'inherit' : 'none'};
    position: relative;
    
    flex-direction: column;
    align-items: center;
`;


const RightBlock = styled.div`
    position: relative;
    margin-left: ${props => props.isOverlayOrIsHovered ? '180px' : '70px'};
    width: ${props => props.isOverlayOrIsHovered ? 'calc(100% - 180px)' : 'calc(100% - 70px)'};
    min-height: 100%;
    background: #efefef;
`;
